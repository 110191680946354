import axios from "axios";
import { API_URL } from "./index";

// eslint-disable-next-line
export default
    {
        GetToken() {
            return axios({
                method: 'post',
                url: `${API_URL}get-token`,
                data: {
                    "key": "PvCD5HzBzqJYfd0iHIpSLuoWESoQqb",
                    "secret": "ntscjBz7H4S2rnmIyjWy3nnSyPhi13"
                }
            });
        },
        UserRegister(token, name, lastname, username, password, phone, countryCode, email) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/user-registration`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    name: name,
                    lastname: lastname,
                    username: username,
                    password: password,
                    phone: phone,
                    countryCode: countryCode,
                    email: email
                }
            });
        },
        UserLogin(token, credential, password) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/user-login`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    credential: credential,
                    password: password,
                }
            });
        },
        UpdateUser(token, userId, name, lastname, email, phone, countryCode) {
            const updateData = {};

            if (name !== undefined) updateData.name = name;
            if (lastname !== undefined) updateData.lastname = lastname;
            if (email !== undefined) updateData.email = email;
            if (phone !== undefined) updateData.phone = phone;
            if (countryCode !== undefined) updateData.countryCode = countryCode;

            return axios({
                method: 'post',
                url: `${API_URL}flow/update-user`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: userId,
                    updateData: updateData
                }
            });
        },
        UpdateUserPassword(token, userId, password) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/update-user`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: userId,
                    updateData: {
                        password: password
                    }
                }
            });
        },
        CreateCollection(token, title, userID) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collection-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    title: title,
                    description: "Description",
                    settings: "Settings",
                    userId: userID
                }
            });
        },
        UpdateCollection(token, collectionId, title) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collection-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: collectionId,
                    updateData: {
                        title: title
                    }
                }
            });
        },
        DeleteCollection(token, collectionId) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collection-delete`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: collectionId
                }
            });
        },
        GetCollections(token) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collections-by-user-id`,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        },
        CreateAPI(token, collectionId, title, method, url, authorization, headers, body) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/api-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    collectionId: collectionId,
                    title: title,
                    method: method,
                    url: url,
                    auth: authorization,
                    headers: headers,
                    body: body
                }
            });
        },
        UpdateAPI(token, collectionId, apiId, title, method, url, authorization, headers, body) {
            const updateData = {
                method: method,
                url: url,
                auth: authorization,
                headers: headers,
                body: body,
            };

            if (title !== null) {
                updateData.title = title;
            }

            // if (basicAuth !== null) {
            //     updateData.basicAuth = basicAuth;
            // }

            return axios({
                method: 'post',
                url: `${API_URL}flow/api-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    collectionId: collectionId,
                    apiId: apiId,
                    updateData: updateData
                }
            });
        }
    }