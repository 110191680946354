import React, { useContext, useEffect, useState } from 'react';
import '../../Views/Layout.css';
import { MdModeEdit } from "react-icons/md";
import { GeneralContext } from '../../GeneralProvider';
import { useLanguage } from '../../LanguageProvider';
import Authorization from './Views/Authorization';
import Headers from './Views/Headers';
import Body from './Views/Body';
import axios from 'axios';
import CircularProgress from '../../components/CircularProgress/CircularProgress';

export default function ApiPage() {
  const { language, getTranslation } = useLanguage();
  const { handleAddApi, selectedApi, handleUpdateApi, handleSaveAsApi, activeTab,
    setActiveTab, bearerToken, addToCollectionId } = useContext(GeneralContext);

  const [headers, setHeaders] = useState([{ key: '', value: '', description: '' }]);
  const [method, setMethod] = useState('GET');
  const [apiURL, setApiURL] = useState('');
  const [apiTitle, setApiTitle] = useState("");
  const [code, setCode] = useState(JSON.stringify({}, null, 2));
  const [selectedAuth, setSelectedAuth] = useState('no-auth');
  const [basicAuth, setBasicAuth] = useState({ username: '', password: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Validate URL function
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  // Function to check if URL is valid and if the form data has changed
  const isFormValid = () => {
    return isValidUrl(apiURL);
  };

  const sendAPIRequest = async (method, url, headers, body, authType, basicAuth) => {
    try {
      let authHeaders = {};
      if (authType === "basic-auth" && basicAuth.username && basicAuth.password) {
        const encodedCredentials = btoa(`${basicAuth.username}:${basicAuth.password}`);
        authHeaders = { Authorization: `Basic ${encodedCredentials}` };
      }

      if (authType === "Bearer" && bearerToken) {
        authHeaders = { Authorization: `Bearer ${bearerToken}` };
      }

      const options = {
        method: method,
        url: url,
        headers: {
          ...headers.reduce((acc, header) => {
            if (header.key && header.value) {
              acc[header.key] = header.value;
            }
            return acc;
          }, {}),
          ...authHeaders
        },
        data: body
      };

      if (method === "GET") {
        delete options.data;
      }

      const response = await axios(options);
      return response.data;
    } catch (error) {
      console.error("API isteği sırasında hata oluştu:", error);
      throw error;
    }
  };

  const checkIfChanged = () => {

    if (selectedAuth === "basic-auth") {
      return (
        headers !== selectedApi.headers ||
        method !== selectedApi.method ||
        apiURL !== selectedApi.url ||
        apiTitle !== selectedApi.apiTitle ||
        code !== selectedApi.body ||
        selectedAuth !== selectedApi.auth.type ||
        basicAuth.username !== selectedApi.auth?.credentials?.username ||
        basicAuth.password !== selectedApi.auth?.credentials?.password
      );
    } else {
      return (
        headers !== selectedApi.headers ||
        method !== selectedApi.method ||
        apiURL !== selectedApi.url ||
        apiTitle !== selectedApi.apiTitle ||
        code !== selectedApi.body ||
        selectedAuth !== selectedApi.auth.type
      );
    }
  };

  useEffect(() => {
    const varApiTitle = selectedApi.apiTitle ? selectedApi.apiTitle : selectedApi.title
    if (Object.keys(selectedApi).length !== 0) {
      if (selectedApi.auth.type !== 'basic-auth') {
        setHeaders(selectedApi.headers);
        setMethod(selectedApi.method);
        setApiURL(selectedApi.url);
        setApiTitle(varApiTitle);
        setCode(selectedApi.body);
        setSelectedAuth(selectedApi.auth ? selectedApi.auth.type : 'no-auth');
        setBasicAuth({ username: "", password: "" });
        setResult(selectedApi.response);
      } else {
        setHeaders(selectedApi.headers);
        setMethod(selectedApi.method);
        setApiURL(selectedApi.url);
        setApiTitle(varApiTitle);
        setCode(selectedApi.body);
        setSelectedAuth(selectedApi.auth ? selectedApi.auth.type : 'no-auth');
        setBasicAuth({ username: selectedApi.auth.credentials.username, password: selectedApi.auth.credentials.password });
        setResult(selectedApi.response);
      }
    } else {
      setHeaders([{ key: '', value: '', description: '' }]);
      setMethod('GET');
      setApiURL('');
      setApiTitle(getTranslation('newApi'));
      setCode(JSON.stringify({}, null, 2));
      setSelectedAuth({ type: 'no-auth' });
      setBasicAuth({ username: "", password: "" });
      setResult("");
    }
  }, [selectedApi, getTranslation]);

  useEffect(() => {
    if (Object.keys(selectedApi).length === 0) {
      setApiTitle(getTranslation('newApi'));
    }
  }, [language, getTranslation, selectedApi]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isEditing && event.key === 'Enter') {
        setIsEditing(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing]);

  const handleChangeBasicAuth = (event) => {
    const { name, value } = event.target;
    setBasicAuth((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function getBaseUrl(fullUrl) {
    try {
      const url = new URL(fullUrl);
      return `${url.protocol}//${url.host}`;
    } catch (error) {
      console.error("Invalid URL provided:", error);
      return null;
    }
  }

  function getEndPoint(fullUrl) {
    try {
      const url = new URL(fullUrl);
      return url.pathname;
    } catch (error) {
      console.error("Invalid URL provided:", error);
      return null;
    }
  }

  const handleSend = async () => {
    setIsLoading(true);
    let authObj = {}

    if (selectedAuth === 'basic-auth') {
      authObj = {
        type: selectedAuth,
        credentials: basicAuth
      }
    }

    else if (selectedAuth === 'Bearer') {
      authObj = {
        type: 'Bearer',
        token: bearerToken
      }
    }

    else {
      authObj = {
        type: 'no-auth'
      }
    }

    let newApi = {
      apiTitle: apiTitle,
      headers: headers,
      method: method,
      url: apiURL,
      body: code,
      auth: authObj,
      baseUrl: getBaseUrl(apiURL),
      endpoint: getEndPoint(apiURL),
      documentationName: apiTitle
    };

    try {
      const response = await sendAPIRequest(
        newApi.method,
        newApi.url,
        newApi.headers,
        newApi.body,
        selectedAuth,
        basicAuth
      );

      const formattedResponse = JSON.stringify(response, null, 2);
      setResult(formattedResponse);
      newApi.response = formattedResponse;
    } catch (error) {
      const formattedResponse = JSON.stringify(error, null, 2);
      setResult(formattedResponse);
      newApi.response = formattedResponse;
      console.error("API isteği hatası:", error);
      newApi.error = error.toString();
    } finally {
      setIsLoading(false);
    }

    handleAddApi(newApi);
  };

  const getOptionStyle = (option) => {
    switch (option) {
      case 'GET':
        return { color: '#3fc462', fontWeight: '600' };
      case 'POST':
        return { color: '#d9db40', fontWeight: '600' };
      case 'PUT':
        return { color: '#3c6ad6', fontWeight: '600' };
      case 'DELETE':
        return { color: '#e3a6b4', fontWeight: '600' };
      default:
        return {};
    }
  };

  const handleMethodChange = (event) => {
    setMethod(event.target.value);
  };

  const handleInputChange = (event) => {
    setApiURL(event.target.value);
  };

  const handleDefaultTextClick = () => {
    setIsEditing(true);
  };

  const handleDefaultTextChange = (event) => {
    setApiTitle(event.target.value);
  };

  const handleDefaultTextBlur = () => {
    setIsEditing(false);
  };

  const handleSave = (type) => {

    let authObj = {}

    if (selectedAuth === 'basic-auth') {
      authObj = {
        type: selectedAuth,
        credentials: basicAuth
      }
    }

    else if (selectedAuth === 'Bearer') {
      authObj = {
        type: 'Bearer',
        token: bearerToken
      }
    }

    else {
      authObj = {
        type: 'no-auth'
      }
    }

    let updatedApi = {
      apiTitle: apiTitle,
      headers: headers,
      method: method,
      url: apiURL,
      body: code,
      auth: authObj,
      baseUrl: getBaseUrl(apiURL),
      endpoint: getEndPoint(apiURL),
      documentationName: apiTitle,
      response: result,
      collectionId: addToCollectionId
    };

    if (type === "save") {
      handleUpdateApi(selectedApi.apiId, updatedApi);

      setHeaders(updatedApi.headers);
      setMethod(updatedApi.method);
      setApiURL(updatedApi.url);
      setApiTitle(updatedApi.apiTitle);
      setCode(updatedApi.body);
      setSelectedAuth(updatedApi.auth.type);
      if (selectedAuth === "basic-auth") {
        setBasicAuth(updatedApi.auth.credentials);
      }
      setResult(updatedApi.response);
    } else if (type === "saveAs") {
      handleSaveAsApi(updatedApi);
    }
  }

  return (
    <div className='main-page'>
      <div
        onClick={handleDefaultTextClick}
        style={{ marginTop: '10px', cursor: 'pointer', marginBottom: '10px' }}
      >
        {isEditing ? (
          <input
            type="text"
            value={apiTitle}
            onChange={handleDefaultTextChange}
            onBlur={handleDefaultTextBlur}
            autoFocus
            maxLength={30}
          />
        ) : (
          <span style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>{apiTitle} <MdModeEdit /></span>
        )}
      </div>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <select value={method} onChange={handleMethodChange} className="custom-select">
          <option style={getOptionStyle('GET')} value="GET">GET</option>
          <option style={getOptionStyle('POST')} value="POST">POST</option>
          <option style={getOptionStyle('PUT')} value="PUT">PUT</option>
          <option style={getOptionStyle('DELETE')} value="DELETE">DELETE</option>
        </select>
        <input
          type="text"
          value={apiURL}
          onChange={handleInputChange}
          placeholder={getTranslation('enterURL')}
          style={{ flex: 1, marginRight: '15px' }}
        />
        <button
          onClick={handleSend}
          style={{ fontSize: '15px', fontWeight: '600' }}
          disabled={!isFormValid() || isLoading}
        >
          {isLoading ? <CircularProgress /> : getTranslation('send')}
        </button>
      </div>
      <div className="container">
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
          <div className="tabs">
            <button
              className={`tab ${activeTab === 'authorization' ? 'active' : ''}`}
              onClick={() => handleTabClick('authorization')}
            >
              Authorization
            </button>
            <button
              className={`tab ${activeTab === 'headers' ? 'active' : ''}`}
              onClick={() => handleTabClick('headers')}
            >
              Headers
            </button>
            <button
              className={`tab ${activeTab === 'body' ? 'active' : ''}`}
              onClick={() => handleTabClick('body')}
            >
              Body
            </button>
          </div>

          {(Object.keys(selectedApi).length !== 0) &&
            <div className="tabs" style={{ alignItems: 'flex-end' }}>
              <button
                onClick={() => handleSave("save")}
                disabled={!checkIfChanged()}
                style={{ height: '35px' }}
              >
                {getTranslation('save')}
              </button>

              <button
                onClick={() => handleSave("saveAs")}
                style={{ height: '35px' }}
              >
                {getTranslation('saveAs')}
              </button>
            </div>
          }
        </div>
        <div className='tab-content'>
          {activeTab === 'authorization' && <div><Authorization selectedAuth={selectedAuth} setSelectedAuth={setSelectedAuth} basicAuth={basicAuth} handleChangeBasicAuth={handleChangeBasicAuth} /></div>}
          {activeTab === 'headers' && <div><Headers headers={headers} setHeaders={setHeaders} /></div>}
          {activeTab === 'body' && <div><Body code={code} setCode={setCode} result={result} /></div>}
        </div>
      </div>
    </div>
  );
}
