import React, { useState } from 'react';
import './Calendar.css';
import { useLanguage } from '../../../LanguageProvider';

const Calendar = () => {
  const { getTranslation } = useLanguage();

  const monthNames = [getTranslation('january'), getTranslation('february'), getTranslation('march'), getTranslation('april'), getTranslation('may'), getTranslation('june'), getTranslation('july'), getTranslation('august'), getTranslation('september'), getTranslation('october'), getTranslation('november'), getTranslation('december')];
  const dayNames = [getTranslation('sunday'), getTranslation('monday'), getTranslation('tuesday'), getTranslation('wednesday'), getTranslation('thursday'), getTranslation('friday'), getTranslation('saturday')];

  const currentMonth = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const tasks = [
    { id: 1, name: 'Task 1', startDate: new Date(2024, 0, 2), endDate: new Date(2024, 0, 5) }, // 2 Ocak - 5 Ocak
    { id: 2, name: 'Task 2', startDate: new Date(2024, 9, 16), endDate: new Date(2024, 9, 19) },  // 8 Ekim - 14 Ekim
    { id: 3, name: 'Task 3', startDate: new Date(2024, 9, 13), endDate: new Date(2024, 9, 17) },
    { id: 4, name: 'Task 4', startDate: new Date(2024, 9, 13), endDate: new Date(2024, 9, 19) },
  ];

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  const daysInMonth = [];
  const daysInSelectedMonth = new Date(2024, selectedMonth + 1, 0).getDate();
  for (let day = 1; day <= daysInSelectedMonth; day++) {
    const date = new Date(2024, selectedMonth, day);
    const dayName = dayNames[date.getDay()];
    daysInMonth.push({
      // date: `${day} ${monthNames[selectedMonth]}`,
      date: day,
      dayName: dayName,
      fullDate: date,
    });
  }

  const tasksInMonth = tasks.filter((task) => {
    const taskStartMonth = task.startDate.getMonth();
    const taskEndMonth = task.endDate.getMonth();
    return (
      taskStartMonth === selectedMonth || taskEndMonth === selectedMonth
    );
  });

  return (
    <>
      <div className="month-selector">
        <p className='page-title'>{getTranslation('selectMonth')}: </p>
        <select id="month" style={{marginLeft:'15px'}} value={selectedMonth} onChange={handleMonthChange}>
          {monthNames.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className='card' style={{ maxHeight: '85%', height: '85%' }}>

        <div className="calendar-container">
          <div className="calendar-grid">
            {daysInMonth.map((dayData, index) => (
              <div key={index} className="calendar-day">
                <div className="day-name">
                  <div style={{ fontSize: '16px', fontWeight: '600' }}>{dayData.date}</div>
                  <div style={{ fontSize: '11px', color: '#9191AA' }}>{dayData.dayName}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="task-container">
            {tasksInMonth.map((task, taskIndex) => {
              const taskStartDay = task.startDate.getMonth() === selectedMonth
                ? task.startDate.getDate()
                : 1;

              const taskEndDay = task.endDate.getMonth() === selectedMonth
                ? task.endDate.getDate()
                : daysInSelectedMonth;

              const startIndex = taskStartDay - 1;
              const spanDays = taskEndDay - taskStartDay + 1;
              return (
                <React.Fragment key={taskIndex}>
                  <div
                    className="task-slot"
                    style={{ gridColumn: `${startIndex + 1} / span ${spanDays}` }}

                  >
                    <div>
                      {task.name}
                    </div>
                    <div style={{ width: '100%', display: 'flex', fontSize: '10px' }}>
                      {task.startDate.getDate()} - {task.endDate.getDate()} {monthNames[selectedMonth]}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>



        </div>
      </div>
    </>

  );
};

export default Calendar;
