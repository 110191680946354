import React from 'react'
import '../../Views/Layout.css'
import { useLanguage } from '../../LanguageProvider';

export default function TestAutomationPage() {
    const { getTranslation } = useLanguage();

    return (
        <div className='main-page'>
            <p className='page-title'>{getTranslation('testAutomation')}</p>
        </div>
    );
}
