import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from './GeneralProvider';

const ProtectedRoute = ({ element }) => {
  const navigate = useNavigate();
  const sessionToken = sessionStorage.getItem('token');
  const localToken = localStorage.getItem('token');
  const isAuthenticated = !!sessionToken || !!localToken;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return element; // Return the protected component if authenticated
};

export default ProtectedRoute;
