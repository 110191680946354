import React, { useContext, useEffect, useState } from 'react';
import '../../Views/Layout.css';
import { EditorState } from "draft-js";
import { useLanguage } from '../../LanguageProvider';
import RichTextEditor from '../../components/RichTextEditor';
import { GeneralContext } from '../../GeneralProvider';
import { FaPlus } from "react-icons/fa6";
import './DocumentationPage.css';

export default function DocumentationPage() {
    const { getTranslation } = useLanguage();
    const { selectedApi } = useContext(GeneralContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [parameters, setParameters] = useState(() => extractParamsAndTypes(selectedApi.body));

    useEffect(() => {
        setParameters(extractParamsAndTypes(selectedApi.body))
    }, [selectedApi]);

    function extractParamsAndTypes(body) {
        function getType(value) {
            if (typeof value === 'number') {
                return Number.isInteger(value) ? 'Number (Integer)' : 'Number (Float)';
            }
            if (typeof value === 'string') {
                return 'String';
            }
            if (typeof value === 'boolean') {
                return 'Boolean';
            }
            if (Array.isArray(value)) {
                const itemType = value.length > 0 ? getType(value[0]) : 'Unknown';
                return `Array (${itemType})`;
            }
            if (value === null) {
                return 'Null';
            }
            if (typeof value === 'object') {
                return 'Object';
            }
            return 'Unknown';
        }

        function processObject(obj, parentKey = '') {
            const result = [];

            for (const [key, value] of Object.entries(obj)) {
                const fullKey = parentKey ? `${parentKey}.${key}` : key;
                const type = getType(value);

                if (type === 'Object') {
                    result.push({ key: fullKey, type: 'Object', description: '', required: false });
                    result.push(...processObject(value, fullKey));
                } else if (type.startsWith('Array')) {
                    result.push({ key: fullKey, type: type, description: '', required: false });
                    if (value.length > 0 && typeof value[0] === 'object') {
                        result.push(...processObject(value[0], fullKey));  // Process the structure of the first item
                    }
                } else {
                    result.push({ key: fullKey, type: type, description: '', required: false });
                }
            }

            return result;
        }

        try {
            const parsedBody = JSON.parse(body);
            return processObject(parsedBody);
        } catch (error) {
            console.error("Invalid JSON provided:", error);
            return [];
        }
    }

    function handleInputChange(index, field, value) {
        const updatedParameters = [...parameters];
        updatedParameters[index] = { ...updatedParameters[index], [field]: value };
        setParameters(updatedParameters);
    }

    function handleAddParameter() {
        setParameters([...parameters, { key: '', type: 'String', description: '', required: false }]);
    }

    function handleDeleteParameter(index) {
        setParameters(parameters.filter((_, i) => i !== index));
    }

    return (
        <div className='main-page'>
            <p className='page-title'>{selectedApi.apiTitle}</p>
            {Object.keys(selectedApi).length !== 0 ? (
                <>
                    <p style={{ fontWeight: '600' }}>METHOD: <span style={{ fontWeight: '400' }}>{selectedApi.method ? selectedApi.method : "-"}</span></p>
                    <p style={{ fontWeight: '600' }}>BASE URL: <span style={{ fontWeight: '400' }}>{selectedApi.baseUrl ? selectedApi.baseUrl : "-"}</span></p>
                    <p style={{ fontWeight: '600' }}>ENDPOINT: <span style={{ fontWeight: '400' }}>{selectedApi.endpoint ? selectedApi.endpoint : "-"}</span></p>

                    <RichTextEditor editorState={editorState} setEditorState={setEditorState} />
                    <p style={{ marginTop: '25px' }}>{getTranslation('parameters')}</p>



                    <div className='card' style={{ maxHeight: '35%', overflowY: 'auto' }}>
                        <button style={{ alignItems: 'center', display: 'flex', marginBottom: '15px' }} onClick={handleAddParameter}>
                            <FaPlus style={{ marginRight: '10px' }} /> {getTranslation('addParameter')}
                        </button>

                        <div className='card' style={{maxHeight:'320px',overflowY:'auto',boxShadow:'none'}}>
                            <div className="params-container">
                                {parameters.map((param, index) => (
                                    <div key={index} className="param-item-row">
                                        <input
                                            type="text"
                                            placeholder={getTranslation('name')}
                                            value={param.key}
                                            onChange={(e) => handleInputChange(index, 'key', e.target.value)}
                                            className='param-input'
                                        />
                                        <select
                                            value={param.type}
                                            onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                            className='param-input'
                                        >
                                            <option value="Number (Integer)">Number (Integer)</option>
                                            <option value="Number (Float)">Number (Float)</option>
                                            <option value="String">String</option>
                                            <option value="Boolean">Boolean</option>
                                            <option value="Array (Number (Integer))">Array (Number (Integer))</option>
                                            <option value="Array (Number (Float))">Array (Number (Float))</option>
                                            <option value="Array (String)">Array (String)</option>
                                            <option value="Array (Object)">Array (Object)</option>
                                            <option value="Object">Object</option>
                                            <option value="Null">Null</option>
                                            <option value="Unknown">Unknown</option>
                                        </select>
                                        <input
                                            type="text"
                                            placeholder={getTranslation('description')}
                                            value={param.description}
                                            onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                            className='param-input'
                                        />
                                        <label style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', fontSize: '12px', cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                checked={param.required}
                                                onChange={(e) => handleInputChange(index, 'required', e.target.checked)}
                                                className='param-input'
                                            />
                                            {getTranslation('required')}
                                        </label>
                                        <button onClick={() => handleDeleteParameter(index)} className="delete-button">
                                            {getTranslation('delete')}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </>
            ) : (
                <div style={{ width: '100%', height: '65%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <p>{getTranslation('selectCollection')}</p>
                </div>
            )}
        </div>
    );
}
