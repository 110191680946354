import React from 'react'
import Calendar from './Views/Calendar'

export default function TaskManager() {
  return (
    <div className='main-page'>
        <Calendar/>
    </div>
  )
}
