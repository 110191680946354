import React, { createContext, useEffect, useState } from 'react';
import Services from './services/Services';
import CircularProgress from './components/CircularProgress/CircularProgress';
import { useLanguage } from './LanguageProvider';
import { useToaster } from './ToasterProvider';

const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {

    const { getTranslation } = useLanguage();
    const { toastError, toastSuccess } = useToaster();

    const [apis, setApis] = useState([]);
    const [selectedApi, setSelectedApi] = useState({});
    const [collections, setCollections] = useState([]);
    const [currentPage, setCurrentPage] = useState('collections');
    const [bearerToken, setBearerToken] = useState();
    const [activeTab, setActiveTab] = useState('authorization');
    const [addToCollectionId, setAddToCollectionId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState("");
    const [profilePageSection, setProfilePageSection] = useState("profileInfo");

    const sessionToken = sessionStorage.getItem('token');
    const localToken = localStorage.getItem('token');
    const sessionUser = JSON.parse(sessionStorage.getItem('user'));
    const localUser = JSON.parse(localStorage.getItem('user'));

    const isAuthenticated = !!sessionToken || !!localToken;


    useEffect(() => {
        setToken(localToken ? localToken : sessionToken)
        getAllCollections(localToken ? localToken : sessionToken)
        // eslint-disable-next-line
    }, []);

    //General Functions
    const getUniqueFieldName = (fieldValue, fieldName, existingItems) => {
        const baseFieldValue = fieldValue.replace(/\s\(\d+\)$/, '');

        for (let counter = 0; ; counter++) {
            const newFieldValue = counter === 0 ? baseFieldValue : `${baseFieldValue} (${counter})`;
            if (!existingItems.some(item => item[fieldName] === newFieldValue)) {
                return newFieldValue;
            }
        }
    };

    const handleTokenUpdate = (newToken) => {
        if (newToken) {
            setToken(newToken)
            getAllCollections(newToken);
        }
    };

    const handlePageChange = (page) => {
        setProfilePageSection('profileInfo')
        if (page === "collections" && Object.keys(selectedApi).length > 0) {
            setCurrentPage("apis")
        }
        else {
            setCurrentPage(page);
            if (page === "profile") {
                setSelectedApi({})
                setAddToCollectionId("")
            }
        }
    };

    const handleAddNewApi = (collectionId) => {
        setAddToCollectionId(collectionId)
        setSelectedApi({});
        setCurrentPage('apis');
    };

    const handleBearerTokenChange = (token) => {
        setBearerToken(token);
    };

    const handleSelectApi = (api, collectionId) => {
        setAddToCollectionId(collectionId)
        if (currentPage === "collections") {
            setCurrentPage("apis")
        }

        if (api.apiId === selectedApi.apiId) {
            setSelectedApi({});
            if (currentPage === "apis") {
                setCurrentPage("collections")
            }
        }

        else {
            setSelectedApi(api);
        }
    };

    const handleProfileSectionChange = (section) => {
        setProfilePageSection(section)
    }

    // API Functions
    const handleAddApi = (api) => {
        // API Create ederken basic-auth ise username password yolla

        if (Object.keys(selectedApi).length === 0) {
            // setIsLoading(true)
            const targetCollection = collections.find(collection => collection.collectionId === addToCollectionId);
            const isDuplicate = targetCollection.apis.some(req => req.apiTitle === api.apiTitle);
            const newName = isDuplicate
                ? getUniqueFieldName(api.apiTitle, 'apiTitle', targetCollection.apis)
                : api.apiTitle;
            Services.CreateAPI(token, addToCollectionId, newName, api.method, api.url, api.auth, api.headers, api.body)
                .then((res) => {
                    if (res.data.status) {
                        getAllCollections()
                        setSelectedApi(res.data.result)
                        toastSuccess(getTranslation('successMessage'))
                    }
                    else {
                        toastError(getTranslation('somethingWentWrong'))
                        console.log(res.data)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
    };

    const handleUpdateApi = (apiId, updatedApi) => {
        // API Update ederken basic-auth ise username password yolla

        setIsLoading(true)

        const targetCollection = collections.find(collection => collection.collectionId === addToCollectionId);
        const isDuplicate = targetCollection.apis.some(req => req.apiTitle === updatedApi.apiTitle);
        const newName = isDuplicate
            ? getUniqueFieldName(updatedApi.apiTitle, 'apiTitle', targetCollection.apis)
            : updatedApi.apiTitle;

        const title = selectedApi.apiTitle === updatedApi.apiTitle ? null : newName

        console.log(updatedApi.auth);

        Services.UpdateAPI(token, addToCollectionId, apiId, title, updatedApi.method, updatedApi.url, updatedApi.auth, updatedApi.headers, updatedApi.body)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    const modifiedResult = {
                        ...res.data.result,
                        apiTitle: res.data.result.title ? res.data.result.title : selectedApi.apiTitle,
                        apiId: selectedApi.apiId
                    };
                    delete modifiedResult.title;
                    setSelectedApi(modifiedResult);
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }

            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleSaveAsApi = (api) => {
        setIsLoading(true)
        const targetCollection = collections.find(collection => collection.collectionId === addToCollectionId);
        const isDuplicate = targetCollection.apis.some(req => req.apiTitle === api.apiTitle);
        const newName = isDuplicate
            ? getUniqueFieldName(api.apiTitle, 'apiTitle', targetCollection.apis)
            : api.apiTitle;

        Services.CreateAPI(token, addToCollectionId, newName, api.method, api.url, api.auth, api.headers, api.body)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    setSelectedApi(res.data.result)
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    // Collection Functions
    const handleAddNewCollection = (name) => {
        setIsLoading(true)
        const newName = getUniqueFieldName(name, 'collectionTitle', collections);

        const userID = sessionUser ? sessionUser.id : localUser.id

        Services.CreateCollection(token, newName, userID)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const getAllCollections = (inFuncToken) => {
        setIsLoading(true)

        let useToken = inFuncToken ? inFuncToken : token

        Services.GetCollections(useToken)
            .then((res) => {
                if (res.data.status) {
                    setCollections(res.data.result);
                }
                else {
                    setCollections([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    }

    const handleUpdateCollectionTitle = (collectionId, newName) => {
        setIsLoading(true)
        const uniqueName = getUniqueFieldName(newName, 'collectionTitle', collections);
        Services.UpdateCollection(token, collectionId, uniqueName)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleDeleteCollection = (collectionId) => {
        setIsLoading(true)
        Services.DeleteCollection(token, collectionId)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));

        if (selectedApi.collectionId === collectionId) {
            setSelectedApi({});
        }

        if (currentPage === 'apis') {
            setCurrentPage('collections');
        }
    };

    //User Functions
    const handleUpdateUser = (userId, name, lastname, email, phone, countryCode) => {
        setIsLoading(true);

        return new Promise((resolve, reject) => {
            Services.UpdateUser(token, userId, name, lastname, email, phone, countryCode)
                .then((res) => {
                    if (res.data.status) {
                        toastSuccess(getTranslation('successMessage'));
                        resolve(true);  // İşlem başarılı olursa resolve ile true döndür
                    } else {
                        toastError(getTranslation('somethingWentWrong'));
                        console.log(res.data);
                        resolve(false); // İşlem başarısız olursa resolve ile false döndür
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err); // Hata durumunda reject ile Promise'ı reddet
                })
                .finally(() => setIsLoading(false));
        });
    };

    const handleUpdateUserPassword = (userId, password) => {
        setIsLoading(true);

        return new Promise((resolve, reject) => {
            Services.UpdateUserPassword(token, userId, password)
                .then((res) => {
                    console.log(res.data.result);
                    if (res.data.status) {
                        toastSuccess(getTranslation('successMessage'));
                        resolve(true);  // İşlem başarılı olursa resolve ile true döndür
                    } else {
                        toastError(getTranslation('somethingWentWrong'));
                        console.log(res.data);
                        resolve(false); // İşlem başarısız olursa resolve ile false döndür
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err); // Hata durumunda reject ile Promise'ı reddet
                })
                .finally(() => setIsLoading(false));
        });
    };

    return (
        <GeneralContext.Provider value={{
            currentPage,
            handlePageChange,
            bearerToken,
            handleBearerTokenChange,
            handleAddApi,
            apis,
            setApis,
            handleSelectApi,
            selectedApi,
            handleAddNewApi,
            handleUpdateApi,
            handleSaveAsApi,
            activeTab,
            setActiveTab,
            handleAddNewCollection,
            collections,
            addToCollectionId,
            handleUpdateCollectionTitle,
            handleDeleteCollection,
            isAuthenticated,
            handleTokenUpdate,
            profilePageSection,
            handleProfileSectionChange,
            handleUpdateUser,
            handleUpdateUserPassword
        }}>
            {isLoading ?
                <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <CircularProgress style={{ width: '30px', height: '30px' }} />
                </div>
                :
                children
            }
        </GeneralContext.Provider>
    );
};

export { GeneralProvider, GeneralContext };
